import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { formatDuration } from '@flyblack/common/util';
import { Airport, FixedSchedule, FlexibleSchedule, FlightType, ScheduleType } from '@flyblack/common/domains';

import Icon from '@flyblack/common/components/Icon';
import Spacer from '@flyblack/common/components/Spacer';
import Typography from '@flyblack/common/components/Typography';

import TerminalPopup from './TerminalPopup';

interface Props {
  sourceAirport: Airport;
  destinationAirport: Airport;
  schedule: FixedSchedule | FlexibleSchedule;
  durationInMinutes: number;
  eventName?: string;
  type: FlightType;
}

const TripSectionItinerary: React.FC<Props> = ({
  sourceAirport,
  destinationAirport,
  schedule,
  durationInMinutes,
  eventName,
  type
}) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      {eventName && (
        <React.Fragment>
          <Spacer xs={2} />

          <Typography is="span" type="halcyon" className="text-center">
            {eventName}
          </Typography>
        </React.Fragment>
      )}

      <Spacer xs={2} sm={4} />

      <div className="flex flex-row justify-between items-center">
        <div className="text-2xl">{sourceAirport.displayCode}</div>

        <div className="h-[1px] flex justify-center items-center  bg-flyblack-gray w-full mx-6 relative">
          <Icon type="plane" className="p-1 bg-black" />
        </div>

        <div className="text-2xl">{destinationAirport.displayCode}</div>
      </div>

      <div className="flex flex-row justify-between">
        <Typography is="span" type="hummingbird" className="flex-1" color="lightGray">
          {sourceAirport.name}
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1 flex-grow-[0.5] text-center" color="lightGray">
          {formatDuration(durationInMinutes)}
        </Typography>

        <Typography is="span" type="hummingbird" className="flex-1 text-right" color="lightGray">
          {destinationAirport.name}
        </Typography>
      </div>

      <Spacer xs={2} />

      {type === FlightType.Deal && schedule.type === ScheduleType.Flexible ? (
        <React.Fragment>
          <Typography is="span" type="hummingbird">
            <FormattedMessage
              id={translations.pages.myTrips.detail.tripSection.flexibleScheduleNotice}
              values={{
                start: intl.formatDate(schedule.departureInterval.start, {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }),
                end: intl.formatDate(schedule.departureInterval.end, {
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                })
              }}
            />
          </Typography>

          <Spacer xs={2} sm={4} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <Typography is="span" type="hummingbird" className="flex-1">
                <FormattedDate value={(schedule as FixedSchedule).departsAt} month="long" day="numeric" />
              </Typography>

              <Typography is="span" type="hummingbird" className="flex-1">
                <FormattedDate value={(schedule as FixedSchedule).departsAt} hour="numeric" minute="numeric" />
              </Typography>
            </div>

            <div className="flex flex-col text-right">
              <Typography is="span" type="hummingbird" className="flex-1">
                <FormattedDate value={(schedule as FixedSchedule).arrivesAt} month="long" day="numeric" />
              </Typography>

              <Typography is="span" type="hummingbird" className="flex-1">
                <FormattedDate value={(schedule as FixedSchedule).arrivesAt} hour="numeric" minute="numeric" />
              </Typography>
            </div>
          </div>

          <Spacer xs={2} sm={4} />
        </React.Fragment>
      )}

      <div className="flex flex-row justify-between w-full text-white">
        <div className="flex flex-col mr-[4px]">
          <div className="flex items-center">
            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage id={translations.pages.myTrips.detail.tripSection.departureTerminal} />
            </Typography>

            <TerminalPopup className="ml-2" />
          </div>

          <Typography is="span" type="halcyon" style={{ fontSize: '14px' }}>
            {sourceAirport.terminal
              ? sourceAirport.terminal
              : intl.formatMessage({ id: translations.pages.myTrips.detail.tripSection.tba })}
          </Typography>
        </div>

        <div className="flex flex-col text-right">
          <div className="flex items-center">
            <TerminalPopup className="mr-2" />

            <Typography is="span" type="hummingbird" color="lightGray">
              <FormattedMessage id={translations.pages.myTrips.detail.tripSection.arrivalTerminal} />
            </Typography>
          </div>

          <Typography is="span" type="halcyon" style={{ fontSize: '14px' }}>
            {destinationAirport.terminal
              ? destinationAirport.terminal
              : intl.formatMessage({ id: translations.pages.myTrips.detail.tripSection.tba })}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TripSectionItinerary;
