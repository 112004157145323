import React from 'react';
import range from 'lodash/range';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';

import { translations } from '@/locale';
import { urls } from '@/constants';
import { Pricing } from '@flyblack/common/domains';
import { getMembershipModalMessage, hasValidMembership } from '@/util/membership';

import DoubleContentWithIcon from '@flyblack/common/components/DoubleContentWithIcon';
import Typography from '@flyblack/common/components/Typography';
import Spacer from '@flyblack/common/components/Spacer';
import Icon from '@flyblack/common/components/Icon';
import Button from '@flyblack/common/components/Button';
import Link from '@flyblack/common/components/Link';
import { SessionContext } from '@/components/Context/Session';

interface Props {
  jetDealId: number;
  seats: number;
  price: Pricing;
  className?: string;
}

const ChairSection = ({ jetDealId, seats, price, className }: Props) => {
  const { me } = React.useContext(SessionContext);

  const history = useHistory();

  const membershipModalMessage = getMembershipModalMessage(me.membership.status);

  return (
    <div className={classnames('bg-white flex flex-col w-full p-6 mb-auto text-black max-w-[480px]', className)}>
      <DoubleContentWithIcon
        noSpacer
        top={
          <Typography is="span" type="halcyon">
            <FormattedMessage id={translations.pages.jetDealDetail.seats} values={{ seats }} />
          </Typography>
        }
        bottom={
          <Typography is="span" type="hummingbird" color="lightGray">
            <FormattedMessage id={translations.pages.jetDealDetail.seatsReserved} />
          </Typography>
        }
        icon="seat"
      />

      <Spacer xs={2} sm={3} />

      <div className="flex flex-wrap gap-5 ml-9 md:ml-11">
        {range(seats).map((seat) => (
          <div key={seat} className="flex flex-col items-center">
            <Icon type="chair" sizeClassName="text-[36px] leading-[36px]" />
          </div>
        ))}
      </div>

      <Spacer xs={4} sm={6} />

      <div className="flex justify-between">
        <Typography is="span" type="halcyon" weight="medium">
          <FormattedMessage id={translations.pages.jetDealDetail.total} />
        </Typography>

        <Typography is="span" type="halcyon" weight="medium">
          <FormattedMessage
            id={translations.pages.jetDealDetail.price}
            values={{ symbol: price.symbol, value: price.value }}
          />
        </Typography>
      </div>

      <Spacer xs={3} />

      {me.membership && hasValidMembership(me.membership.status) ? (
        <Button onClick={() => history.push(urls.jetDeals.checkout.get(jetDealId))} appearance="black">
          <FormattedMessage id={translations.inputs.buttons.proceedToCheckout} />
        </Button>
      ) : (
        <Popover className="relative">
          <Popover.Button className="w-full">
            <Button appearance="black" fat className="w-full" trailingIcon="forward">
              <Typography is="div" type="halcyon" className="font-normal">
                <FormattedMessage id={translations.inputs.buttons.proceedToCheckout} />
              </Typography>
            </Button>
          </Popover.Button>

          <Transition
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-80 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-80 opacity-0"
          >
            <Popover.Panel className="absolute -mt-16 shadow z-20 bg-white rounded-md w-full p-5 flex flex-col text-black">
              <Typography is="span" type="halcyon">
                <FormattedMessage id={membershipModalMessage.title} />
              </Typography>

              <Spacer xs={2} />

              <Typography is="span" type="hummingbird" color="lightGray" className="w-3/4">
                <FormattedMessage id={membershipModalMessage.subtitle} />
              </Typography>

              <Spacer xs={2} />

              <div className="flex justify-between items-center">
                <Typography is="span" type="halcyon" className="cursor-pointer">
                  <FormattedMessage id={translations.inputs.buttons.cancel} />
                </Typography>

                <Link to={urls.membership.base} className="ml-auto flex items-center">
                  <FormattedMessage id={translations.inputs.buttons.applyForMembership} />
                  <Icon type="chevronRight" />
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
};

export default ChairSection;
