import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { translations } from '@/locale';
import { urls } from '@/constants';

import { AircraftCategory, AircraftType, GalleryImage } from '@flyblack/common/domains';

import PhotoGallery from '@flyblack/common/components/PhotoGallery';

import placeholder from './placeholder.jpg';

export interface Props {
  images?: GalleryImage[];
  loading?: boolean;
  className?: string;
  airplaneCategory?: AircraftCategory;
  airplaneType?: AircraftType;
}

const ClientPhotoGallery: React.FC<Props> = (props) => {
  const history = useHistory();
  const intl = useIntl();

  return (
    <PhotoGallery
      {...props}
      {...{ placeholder }}
      title={intl.formatMessage({ id: translations.application.common.clientPhotoGallery.title })}
      onButtonClick={() => {
        history.push(
          props.airplaneType
            ? urls.planeDetail.getType(props.airplaneType.id)
            : urls.planeDetail.getCategory(props.airplaneCategory.id)
        );
      }}
    />
  );
};

export default ClientPhotoGallery;
