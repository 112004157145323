import React from 'react';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';

import { SENDBIRD_APP_ID } from '@/config';
import { urls } from './constants';

import 'tailwindcss/tailwind.css';
import './style/base.sass';

import { hasValidMembership, shouldPayMembership } from './util/membership';

import Loading from '@flyblack/common/components/Loading';
import ScrollToTop from '@flyblack/common/components/ScrollToTop';
import ConsumeRedirectRoute from '@flyblack/common/components/Navigation/ConsumeRedirectRoute';

import { SessionContext } from '@/components/Context/Session';
import { SendBirdProvider } from './components/Context/SendBird';

import Home from '@/page/Home/Home';
import Unauthorized from '@/page/Unauthorized';
import Sent from '@/page/MembershipApplication/Sent';
import Apply from '@/page/MembershipApplication/Apply';
import MembershipCheckout from '@/page/MembershipApplication/MembershipCheckout';
import JetDealCheckout from '@/page/JetDealCheckout';
import JetShuttleCheckout from '@/page/JetShuttleCheckout';
import JetCharterCheckout from '@/page/JetCharterCheckout';
import ResultPage from '@/page/Home/ResultPage';

export interface Props extends RouteComponentProps {}

const App: React.FC<Props> = () => {
  const { loading, session, me, reload } = React.useContext(SessionContext);

  const location = useLocation();

  React.useEffect(() => {
    if (matchPath(location.pathname, { path: urls.membership.base, exact: true })) {
      reload();
    }
  }, [location.pathname]);

  return (
    <ScrollToTop>
      <Helmet defaultTitle="FlyBlack" titleTemplate="%s - FlyBlack" />

      {loading ? (
        <Loading visible={true} className="mt-40" center>
          <Loading.Indicator size={120} borderWidth={2} />
        </Loading>
      ) : session && me ? (
        <SendBirdProvider appId={SENDBIRD_APP_ID}>
          <Switch>
            {me.membership && hasValidMembership(me.membership.status) && (
              <Route path={urls.jetDeals.checkout.base} component={JetDealCheckout} />
            )}

            {me.membership && hasValidMembership(me.membership.status) && (
              <Route exact path={urls.jetShuttles.checkout.base} component={JetShuttleCheckout} />
            )}

            {me.membership && hasValidMembership(me.membership.status) && (
              <Route path={urls.jetCharters.checkout.base} component={JetCharterCheckout} />
            )}

            <Route path={urls.membership.sent} component={Sent} />

            <Route path={urls.membership.apply} component={Apply} />

            {me.membership && shouldPayMembership(me.membership.status) && (
              <Route path={urls.membership.checkout.base} component={MembershipCheckout} />
            )}

            <Route path={urls.result.base} component={ResultPage} />

            <Route path={urls.home} component={Home} />

            <ConsumeRedirectRoute render={() => <Redirect to={urls.home} />} />
          </Switch>
        </SendBirdProvider>
      ) : (
        <Switch>
          <Route path={urls.home} component={Unauthorized} />

          <ConsumeRedirectRoute render={() => <Redirect to={urls.home} />} />
        </Switch>
      )}
    </ScrollToTop>
  );
};

export default hot(withRouter(App));
