import React from 'react';
import * as copy from 'copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';

import { translations } from '@/locale';
import { getReferralBonus } from '@/services/api/me';
import { MembershipStatus } from '@/domains/membership';
import { shouldRenewMembership } from '@/util/membership';

import useLoad from '@flyblack/common/hooks/useLoad';
import Avatar from '@flyblack/common/components/Avatar';
import Spacer from '@flyblack/common/components/Spacer';
import Button from '@flyblack/common/components/Button';
import Typography from '@flyblack/common/components/Typography';

import { SessionContext } from '@/components/Context/Session';

import BenefitItem from './BenefitItem';
import DetailBarItem from './DetailBarItem';
import RenewMembershipBar from './RenewMembershipBar';

import gradientImg from '@flyblack/common/assets/gradient.png';

const Detail: React.FC = () => {
  const { me, loading } = React.useContext(SessionContext);

  const { value: bonus, loading: bonusLoading } = useLoad({
    load: () => getReferralBonus()
  });

  const intl = useIntl();

  return (
    !loading &&
    !bonusLoading && (
      <React.Fragment>
        <div className="flex flex-col w-full">
          <div
            className="w-full flex flex-col items-center justify-center"
            style={{ backgroundImage: `url(${gradientImg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
          >
            <Spacer xs={4} sm={8} />

            <Avatar image={me.profilePictureUrl} className="w-32 h-32" />

            <Spacer xs={6} />

            <div className="flex items-center w-full justify-between max-w-[480px]">
              <DetailBarItem
                topText={intl.formatMessage({ id: translations.pages.membership.detail.memberSince })}
                bottomText={intl.formatDate(me.membership.startsAt, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              />

              <DetailBarItem
                topText={intl.formatMessage({ id: translations.pages.membership.detail.memberUntil })}
                bottomText={
                  me.membership.status === MembershipStatus.Expired
                    ? intl.formatMessage({ id: translations.pages.membership.detail.expired })
                    : intl.formatDate(me.membership.endsAt, { year: 'numeric', month: 'short', day: 'numeric' })
                }
              />

              <DetailBarItem
                topText={intl.formatMessage({ id: translations.pages.membership.detail.credits })}
                bottomText={me.credit ? me.credit.value.toString() : '0'}
              />
            </div>

            <Spacer xs={4} sm={8} />
          </div>

          <div className="flex flex-col sm:flex-row h-full">
            <div className="bg-white text-black md:w-1/2">
              <div className="max-w-[480px] ml-auto">
                <div className="flex flex-col px-5 mx-auto lg:mx-[70px] max-w-[340px]">
                  <Spacer xs={4} sm={9} />

                  <Typography is="span" type="hummingbird" className="uppercase tracking-[2px] font-medium">
                    <FormattedMessage id={translations.pages.membership.detail.promoCode.title} />
                  </Typography>

                  <Spacer xs={2} />

                  <Typography is="span" type="flamingo" className="uppercase">
                    {me.referralCode}
                  </Typography>

                  <Spacer xs={1} />

                  <Typography is="span" type="hummingbird" color="darkerGray">
                    <FormattedMessage id={translations.pages.membership.detail.promoCode.subtitle} />
                  </Typography>

                  <Spacer xs={6} />

                  <Button
                    fat
                    appearance="transparent"
                    type="button"
                    className="bg-black"
                    onClick={() =>
                      copy.default(
                        intl.formatMessage(
                          { id: translations.pages.profile.friendReferral.shareMessage },
                          { promoCode: me.referralCode, symbol: bonus.symbol, bonus: bonus.value }
                        )
                      )
                    }
                  >
                    <FormattedMessage id={translations.pages.membership.detail.promoCode.share} />
                  </Button>

                  <Spacer xs={4} />
                </div>
              </div>
            </div>

            <div className="md:w-1/2">
              <div className="max-w-[480px] mr-auto">
                <div className="flex flex-col px-5 mx-auto lg:mx-[70px] max-w-[340px]">
                  <Spacer xs={4} sm={9} />

                  <Typography is="span" type="hummingbird" className="uppercase tracking-[2px] font-medium">
                    <FormattedMessage id={translations.pages.membership.detail.benefits.title} />
                  </Typography>

                  <Spacer xs={6} />

                  <BenefitItem
                    topText={intl.formatMessage({
                      id: translations.pages.membership.detail.benefits.discountedPrices.title
                    })}
                    bottomText={intl.formatMessage({
                      id: translations.pages.membership.detail.benefits.discountedPrices.description
                    })}
                  />

                  <Spacer xs={5} />

                  <BenefitItem
                    topText={intl.formatMessage({
                      id: translations.pages.membership.detail.benefits.otherBenefits.title
                    })}
                    bottomText={intl.formatMessage({
                      id: translations.pages.membership.detail.benefits.otherBenefits.description
                    })}
                  />

                  <Spacer xs={5} />

                  <BenefitItem
                    topText={intl.formatMessage({ id: translations.pages.membership.detail.benefits.pricing.title })}
                    bottomText={intl.formatMessage({
                      id: translations.pages.membership.detail.benefits.pricing.description
                    })}
                  />

                  <Spacer xs={4} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {shouldRenewMembership(me.membership.status) && <RenewMembershipBar />}
      </React.Fragment>
    )
  );
};

export default Detail;
