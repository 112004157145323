import { MembershipStatus } from '@/domains/membership';
import { translations } from '@/locale';

export const hasValidMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.Active, MembershipStatus.ActiveAndRenewable].includes(membershipStatus);

export const shouldPayMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.AwaitingPayment, MembershipStatus.ActiveAndRenewable, MembershipStatus.Expired].includes(
    membershipStatus
  );

export const shouldRenewMembership = (membershipStatus: MembershipStatus) =>
  [MembershipStatus.ActiveAndRenewable, MembershipStatus.Expired].includes(membershipStatus);

const membershipModalMessages = {
  [MembershipStatus.Pending]: {
    title: translations.modals.membershipRedirectModalPending.title,
    subtitle: translations.modals.membershipRedirectModalPending.subtitle
  },
  [MembershipStatus.AwaitingPayment]: {
    title: translations.modals.membershipRedirectModalAwaitingPayment.title,
    subtitle: translations.modals.membershipRedirectModalAwaitingPayment.subtitle
  },
  [MembershipStatus.WaitingList]: {
    title: translations.modals.membershipRedirectModalWaitingList.title,
    subtitle: translations.modals.membershipRedirectModalWaitingList.subtitle
  },
  default: {
    title: translations.modals.membershipRedirectModalExpired.title,
    subtitle: translations.modals.membershipRedirectModalExpired.subtitle
  }
};

export const getMembershipModalMessage = (status) => {
  return membershipModalMessages[status] || membershipModalMessages.default;
};
